import axios from "axios";

let step = 0;
let APP_URL =
    "https://xk3f8wj9ri.execute-api.ap-northeast-2.amazonaws.com/prod";
const GSSHOP_URL = "https://m.gsshop.com";
const location = window.location.href;

if (location.startsWith("https://stgpp")) {
    step = 1;
    APP_URL =
        "https://xk3f8wj9ri.execute-api.ap-northeast-2.amazonaws.com/prod";
} else if (
    location.startsWith("https://testpp") ||
    location.startsWith("http://local")
) {
    step = 2;
    APP_URL =
        "https://xk3f8wj9ri.execute-api.ap-northeast-2.amazonaws.com/prod";
}

// basic config for axios

// instance & interceptor
function create(url, options) {
    const instance = axios.create(Object.assign({ baseURL: url }, options));
    return instance;
}

const code = () => {
    const date = new Date();
    const zero = (num) => {
        return String(num).padStart(2, "0");
    };
    return Math.random()
        .toString()
        .substring(2, 7)
        .concat(zero(date.getHours())) // 시(24)
        .concat(Math.random().toString().substring(2, 3))
        .concat(zero(date.getDate())) // 일
        .concat(Math.random().toString().substring(2, 10))
        .concat(zero(date.getMinutes()).substring(0, 1))
        .concat(date.getMinutes() % 10 > 4 ? 5 : 0)
        .concat(Math.random().toString().substring(2, 5));
};

const gsshop = create(GSSHOP_URL);
const basic = create(APP_URL);
const APP_TEST_URL =
    "https://hmtj9cqj23.execute-api.ap-northeast-2.amazonaws.com/dev";
const basicTest = create(APP_TEST_URL);
const testUrls = [
    "/post/item",
    "/reply/items/special",
    "/reply/item",
    "/reply/items",
    "/reply/exceldownload",
    "/rereply/item",
    "/rereply/items",
    "/member/info",
    "/post/item",
    "/post/item/love",
    "/post/item/notice",
];
const testCheck = (url) => {
    return (
        step == 2 &&
        testUrls.some((testUrl) => {
            return testUrl == url;
        })
    );
};

const setValue = (formData, name, value) => {
    if (value) {
        formData.append(name, value);
    }
};

async function getData(url, params) {
    try {
        params = params ? { params } : {};
        if (step) {
            if (!params.params) {
                params["params"] = {};
            }
            params.params["step"] = step;
        }
        let response = {};
        if (testCheck(url)) {
            let headers = {
                Authorization: code(),
            };
            response = await basicTest.get(url, { ...params, headers });
        } else {
            let headers = {
                Authorization: code(),
            };
            response = await basic.get(url, { ...params, headers });
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

async function postData(url, formData) {
    try {
        if (step) {
            formData.append("step", step);
        }
        let response = {};
        if (testCheck(url)) {
            response = await basicTest.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: code(),
                },
            });
        } else {
            response = await basic.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: code(),
                },
            });
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function deleteData(url, params) {
    try {
        params = params ? { params } : {};
        if (step) {
            if (!params.params) {
                params["params"] = {};
            }
            params.params["step"] = step;
        }
        let response = {};
        if (testCheck(url)) {
            let headers = {
                Authorization: code(),
            };
            response = await basicTest.delete(url, { ...params, headers });
        } else {
            let headers = {
                Authorization: code(),
            };
            response = await basic.delete(url, { ...params, headers });
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

async function patchData(url, formData) {
    try {
        if (step) {
            formData.append("step", step);
        }
        let response = {};
        if (testCheck(url)) {
            response = await basicTest.patch(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: code(),
                },
            });
        } else {
            response = await basic.patch(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: code(),
                },
            });
        }
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function getDataFromGs(url, params) {
    try {
        params = params ? { params } : {};
        let response = await gsshop.get(url, params);
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

async function getDataFromGsPost(url, params) {
    try {
        params = params ? params : {};
        let response = await gsshop.post(url, params);
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

export {
    setValue,
    getData,
    getDataFromGs,
    getDataFromGsPost,
    postData,
    patchData,
    deleteData,
};
