<template>
	<v-app>
		<v-card class="overflow-hidden" elevation="0">
			<Top />
			<v-sheet
				id="scrolling-techniques-4"
				class="overflow-y-auto"
				:max-height="maxHeight"
				:style="paddingHeight"
			>
				<v-container class="pa-0">
					<router-view />
				</v-container>
			</v-sheet>
		</v-card>
		<SbType1 serialid="commonSnackbar"></SbType1>
		<Bottom />
		<iframe style="display: none" ref="mseqFrame"></iframe>
	</v-app>
</template>

<script>
import Top from "@/components/navi/Top.vue";
import Bottom from "@/components/navi/Bottom.vue";
import SbType1 from "@/components/common/snackbar/Type1.vue";
import "@/styles/overrides.scss";

export default {
	name: "App",
	data: () => ({
		maxHeight: window.innerHeight,
	}),
	components: { Top, Bottom, SbType1 },
	computed: {
		paddingHeight() {
			return (
				"padding: " +
				(this.$store.state.topExposYn ? "171" : "48") +
				"px 0 50px 0"
			);
		},
		mseqData() {
			return this.$store.state.mseqRequest.flag;
		},
	},
	watch: {
		mseqData() {
			this.clickTrac(
				this.$store.state.mseqRequest.mseq,
				this.$store.state.mseqRequest.channel
			);
		},
	},
	methods: {
		handleResize() {
			this.maxHeight = window.innerHeight;
		},
		clickTrac(mseq, channel) {
			var url =
				"https://m.gsshop.com/mobile/commonClickTrac.jsp?mseq=" +
				mseq +
				"&channel=" +
				channel;
			this.$refs.mseqFrame.contentWindow.location.replace(url);
		},
		inflow() {
			var mseq = window.location.search;
			if (mseq.indexOf("mseq=") > 0) {
				if (mseq) mseq = mseq.split("mseq=")[1];
				if (mseq) mseq = mseq.split("&")[0];
				if (mseq) mseq = "&mseq=" + mseq;
			} else {
				mseq = "";
			}

			var channel = window.location.pathname.split("/");

			this.$refs.mseqFrame.contentWindow.location.replace(
				"https://event.gsshop.com/dummy/inflow.jsp?channel=" +
					channel[2] +
					mseq
			);
		},
	},
	mounted() {
		window.addEventListener("resize", this.handleResize);
		//this.$store.commit("SET_HISTORY_COUNT", window.history.length);
		this.inflow();
	},
	unmounted() {
		window.removeEventListener("resize", this.handleResize);
	},
};
</script>

<style scoped lang="scss">
.theme--light.v-sheet {
	background-color: #f9fafc;
}
.v-list-item--dense {
	.v-list-item__title {
		font-size: 1rem;
		font-weight: 600;
		color: #191923;
	}
	.v-list-item__subtitle {
		font-size: 14px;
		color: #191923 !important;
		line-height: 20px;
		-webkit-line-clamp: 5;
		font-weight: 400;
	}
}
.comment-item {
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
	+ .btn-expend-more {
		// 댓글의 더보기 버튼 위치 스타일 재정의
		margin-left: 52px !important;
	}
}

.comment-sub-item {
	border-top: 1px solid #eaecf5;
	background-color: #fff;
	.v-btn__content {
		color: #76768e;
	}
	.button-box {
		color: #76768e;
		.v-btn {
			color: #76768e !important;
			min-width: 30px;
		}
	}
	.v-list-item__subtitle {
		-webkit-line-clamp: 5;
		font-size: 14px;
		font-weight: 400;
		color: #191923;
	}
}
.comment-form::v-deep {
	border-radius: 24px;
	height: 40px;
	min-height: 40px;
	.v-input__control > .v-input__slot {
		min-height: 40px;
		padding: 0 9px 0 12px;
		.v-text-field__slot textarea {
			height: 28px;
			min-height: 28px;
			margin: 6px 0;
			outline: none;
		}
	}
	&.v-text-field--outlined.v-input--is-focused fieldset {
		border-width: 1px;
	}
	.v-input__append-inner {
		.mdi-close::before {
			content: "";
			width: 20px;
			height: 20px;
			background-image: url("./components/icons/close_alt_fill.png");
			background-size: 100%;
		}
	}
}
.snackbar-noti1::v-deep {
	.v-snack__wrapper {
		width: 100%;
		max-width: 100%;
		&.v-sheet {
			border-radius: 12px;
		}
		&.theme--dark {
			background-color: rgba(25, 25, 35, 0.74);
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
	}
}
.snackbar-noti2::v-deep {
	padding: 6px 16px 6px;
	background-color: #f9fafc;
	color: #9595b2;
	font-size: 0.7rem;
	.v-snack__wrapper {
		width: 100%;
		&.v-sheet {
			border-radius: 0;
		}
		&.theme--dark {
			margin: 0;
			background-color: #f9fafc;
		}
	}
	.v-snack__content {
		display: flex;
		align-items: center;
		padding: 6px 16px 6px;
	}
}

.admin-sheet::v-deep {
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	.admin-top {
		position: relative;
		padding: 30px 0 0;
		font-size: 16px;
		font-weight: 600;
		color: #191923;
		&:before {
			content: "";
			position: absolute;
			top: 8px;
			left: 50%;
			margin-left: -20px;
			display: block;
			width: 40px;
			height: 4px;
			border-radius: 4px;
			background-color: rgba(149, 149, 178, 0.3);
		}
	}
	.admin-other {
		margin: 0 24px;
		padding: 0;
		//    background-color: #F9FAFC;
		//    border-radius: 10px;
		border-bottom: 1px solid #eaecf5;
	}
}
.admin-button-group {
	display: flex;
	margin: -16px 0 0;
	.v-btn {
		border-radius: 10px;
	}
	.btn-cancel {
		width: calc(45% - 8px);
		margin-right: 8px;
		color: #191923 !important;
		font-weight: 600;
		border-color: #d2d4e5;
	}
	.btn-confirm {
		width: 55%;
		color: #191923 !important;
		font-weight: 600;
	}
}
.theme--light.v-list-item:hover::before {
	opacity: 0;
}

.form-sheet::v-deep {
	position: relative;
	padding-top: 30px;
	padding-bottom: 20px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	&:before {
		content: "";
		position: absolute;
		top: 8px;
		left: 50%;
		margin-left: -20px;
		display: block;
		width: 40px;
		height: 4px;
		border-radius: 4px;
		background-color: rgba(149, 149, 178, 0.3);
	}
}

// 대댓글의 더보기 버튼 스타일
.btn-expend-more {
	font-size: 12px;
	font-weight: 600;
	color: #76768e;
	margin-top: -5px;
	margin-left: 82px !important;
	margin-bottom: 12px;
}
.btn-expand-text {
	margin: 2px 0 0;
	line-height: 20px;
	color: #999;
	font-size: 14px;
}
</style>
