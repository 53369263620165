import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import vuetify from "./plugins/vuetify";

import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

Vue.mixin(mixin);

Vue.use(VuePlyr, {
	plyr: {
		fullscreen: { enabled: false },
	},
	emit: ["ended"],
});

Vue.config.productionTip = false;

window.Kakao.init("7a243a2bdafd3b2513260b4c5578aee7");

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
