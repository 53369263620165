<template>
	<div>
		<v-btn
			v-if="showDownloadBtn && !bottomFlag && !isApp"
			@click="downloadExcel"
			:class="{
				'navi-btn': true,
				'download-comment': true,
				'low-btn': !topBtn,
			}"
			:style="excelBtnPostion"
		>
			<v-icon color="green darken-2">mdi-microsoft-excel</v-icon>
		</v-btn>
		<!-- TODO: 글쓰기 버튼 -> 관리자만 보여줌  			<v-icon
			v-if="isCommentPage"
			@click="this.downloadExcel"
			small
			color="green darken-2"
		>
			mdi-microsoft-excel
		</v-icon>-->
		<v-btn
			v-if="showWriteBtn && !bottomFlag"
			:class="{
				'navi-btn': true,
				'write-article': true,
				'low-btn': !topBtn,
			}"
			:style="writeBtnPostion"
			to="/article"
		>
			<v-icon>mdi-file-plus-outline</v-icon>
		</v-btn>
		<v-btn
			v-if="topBtn"
			:class="{
				'navi-btn': true,
				'page-top': true,
			}"
			:style="topBtnPostion"
			@click="scrollTop"
		>
			<!-- <v-icon>mdi-arrow-up</v-icon> -->
		</v-btn>
		<v-bottom-navigation
			v-if="!isApp"
			:value="value"
			color="#191923"
			class="navigation-box"
			grow
			app
		>
			<v-btn href="https://m.gsshop.com/index.gs?mseq=398042">
				<span class="nav-txt">홈</span>
				<!-- <IconNavBottomHome /> -->
				<v-img
					max-height="28"
					max-width="28"
					src="../icons/nav_bottom_home.png"
				></v-img>
			</v-btn>

			<v-btn href="https://m.gsshop.com/m/sect/category.gs?mseq=398043">
				<span class="nav-txt">카테고리</span>
				<v-img
					max-height="28"
					max-width="28"
					src="../icons/nav_bottom_menu.png"
				></v-img>
			</v-btn>

			<v-btn
				href="https://m.gsshop.com/mygsshop/myshopInfo.gs?mseq=398041"
			>
				<span class="nav-txt">마이쇼핑</span>
				<v-img
					max-height="28"
					max-width="28"
					src="../icons/nav_bottom_user.png"
				></v-img>
			</v-btn>

			<v-btn
				href="https://m.gsshop.com/section/myWishMain.gs?mseq=410808"
			>
				<span class="nav-txt">찜</span>
				<v-img
					max-height="28"
					max-width="28"
					src="../icons/nav_bottom_zzim.png"
				></v-img>
			</v-btn>

			<v-btn href="http://m.gsshop.com/section/recntView?mseq=410809">
				<span class="nav-txt">최근본상품</span>
				<v-img
					max-height="28"
					max-width="28"
					class="recent-img-box"
					:src="rcntPrdImgPath"
				></v-img>
				<!-- <v-icon>mdi-map-marker</v-icon> -->
			</v-btn>
		</v-bottom-navigation>
	</div>
</template>

<script>
import { getExcelReply } from "@/api/reply";

export default {
	created() {
		let matches = document.cookie.match(
			"(^|;) ?" + "lastprdid" + "=([^;]*)(;|$)"
		);
		if (matches) {
			let prdIds = matches ? decodeURIComponent(matches[2]) : "";
			if (prdIds) {
				let rcntPrdId = prdIds.split("%")[0];
				this.rcntPrdImgPath =
					"https://asset.m-gs.kr/prod/" + rcntPrdId + "/1/60";
			}
		}
	},
	data: () => ({
		value: "recent",
		topBtn: false,
		rcntPrdImgPath: "",
		bottomFlag: false,
	}),
	computed: {
		showWriteBtn() {
			return ["article", "profile"].includes(
				this.$route.path.split("/")[1]
			) || !this.adminFlag
				? false
				: true;
		},
		isApp() {
			return !this.$store.state.appInfo.ver == "";
		},
		showDownloadBtn() {
			return this.isCommentPage && this.adminFlag ? true : false;
		},
		isCommentPage() {
			return ["comment"].includes(this.$route.path.split("/")[1]);
		},
		excelBtnPostion() {
			let defaultValue = this.basePosition + 66;
			let adjustValue = this.topBtn ? defaultValue + 66 : defaultValue;

			return "bottom: " + adjustValue + "px";
		},
		writeBtnPostion() {
			let defaultValue = this.basePosition;
			let adjustValue = this.topBtn ? defaultValue + 66 : defaultValue;

			return "bottom: " + adjustValue + "px";
		},
		basePosition() {
			let defaultValue = 66;
			let adjustValue = !this.isApp
				? defaultValue
				: this.isCommentPage
				? defaultValue
				: defaultValue - 60;

			return adjustValue;
		},
		topBtnPostion() {
			let value = this.basePosition;
			return "bottom: " + value + "px";
		},
	},
	methods: {
		showTopBtn() {
			let scrollTop = document.getElementById("scrolling-techniques-4")
				? document.getElementById("scrolling-techniques-4").scrollTop
				: 0;
			if (
				document.getElementById("scrolling-techniques-4").scrollHeight -
					document.getElementById("scrolling-techniques-4")
						.scrollTop >
				950
			) {
				this.bottomFlag = false;
			} else {
				this.bottomFlag = true;
			}
			this.topBtn = scrollTop > 500 ? true : false;
		},
		scrollTop() {
			if (document.getElementById("scrolling-techniques-4")) {
				document.getElementById("scrolling-techniques-4").scrollTop = 0;
			}
		},

		async downloadExcel() {
			const xlsx = await import("xlsx");

			const articleNo = location.pathname.split("/")[2];
			const response = await getExcelReply(articleNo);

			const filtered = response.filter((row) => row);
			const replyRows = filtered.map((response) => ({
				댓글번호: response.sk,
				작성일: response.regDate,
				작성자: response.nickname,
				고객번호: response.custNo,
				메세지: response.message,
				이미지: response.images,
			}));

			const replyJsonList = replyRows;
			//console.log(replyJsonList);

			replyJsonList.forEach((element) => {
				element["이미지"] = element["이미지"]
					? element["이미지"][0]
					: "";
				//엑셀에 데이터에 없는 정보 컬럼 추가하기
				//element["test"] = "!!!!!";
			});

			//console.log(replyRows);
			const worksheet = xlsx.utils.json_to_sheet(replyJsonList);
			const workbook = xlsx.utils.book_new();
			xlsx.utils.book_append_sheet(workbook, worksheet, "댓글목록");
			xlsx.writeFileXLSX(workbook, "ReplySheet" + articleNo + ".xlsx");
		},
		isDownloable() {
			location.pathname.includes("comment");
		},
	},
	mounted() {
		window.addEventListener("scroll", this.showTopBtn, true);
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.showTopBtn, true);
	},
};
</script>

<style lang="scss">
.v-bottom-navigation.navigation-box {
	padding: 0 !important;
	box-shadow: none !important;
	border-top: 1px solid #eaecf5;
	.v-btn {
		min-width: auto !important;
		background-color: #fff;
		.nav-txt {
			padding-top: 2px;
			font-size: 10px;
			// color: #76768E;
		}
	}
}
.recent-img-box {
	border-radius: 50%;
	border: 2px solid #424252;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
	opacity: 0;
}

.navi-btn {
	position: fixed;
	right: 10px;
	min-width: 45px !important;
	height: 45px !important;
	padding: 0 !important;
	&.download-comment {
		transition: bottom 0.1s linear;
		border-radius: 50px;
		background-color: rgb(255, 255, 255) !important;
	}
	&.write-article {
		transition: bottom 0.1s linear;
		border-radius: 50px;
		background-color: rgba(141, 117, 226, 0.9) !important;
	}
	&.page-top {
		border-radius: 0;
		background-color: transparent !important;
		margin-bottom: constant(safe-area-inset-bottom);
		margin-bottom: env(safe-area-inset-bottom);
		background: url(//image.gsshop.com/ui/gsshop/images/sprite_etc.png)
			no-repeat 0 0;
		background-size: 45px auto;
		box-shadow: none;
	}
}
</style>
