import store from "../store";
import {
    setValue,
    getData,
    patchData,
    getDataFromGs,
    getDataFromGsPost,
} from "../api/common";
import { catvid } from "../util";

const getMemberInfo = async function (type, sn) {
    let response = await getData("/member/info", { type: type, sn: sn });
    return response;
};

const getFamilyInfo = async function () {
    const codeMap = {
        416860: "400612",
        416861: "414356",
        416862: "409222",
        420782: "420782",
    };

    return getDataFromGs(
        "/shop/cafe/family/" + codeMap[store.state.channel.selected],
        { custNo: catvid(document.cookie) }
    );
};

const setFamilyInfo = async function (flag) {
    const codeMap = {
        416860: "400612",
        416861: "414356",
        416862: "409222",
        420782: "420782",
    };

    return getDataFromGsPost("/shop/cafe/family/update", {
        custNo: catvid(document.cookie),
        cafeTypNoVal: codeMap[store.state.channel.selected],
        agreeYn: "Y",
        fmlyYn: flag,
    });
};

const setProfile = async function (params) {
    var formData = new FormData();
    setValue(formData, "sn", store.state.myInfo.eSn);
    if (params.nickname) {
        setValue(formData, "nickname", params.nickname);
    }
    if (params.thumb) {
        formData.append("file", params.thumb);
    }
    return patchData("/member/info", formData);
};

export { getMemberInfo, getFamilyInfo, setFamilyInfo, setProfile };
