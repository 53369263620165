import { format, register } from "timeago.js";

const ko_KR = ["초", "분", "시간", "일", "주일", "개월", "년"];
const locale = (diff, idx) => {
    if (idx === 0) return ["방금 전", "곧"];
    let unit = ko_KR[Math.floor(idx / 2)];
    // 1주일 전 -> 7일 전
    if (diff === 1 && idx === 8) {
        diff = 7;
        unit = "일";
    }
    return [`${diff}${unit} 전`, `${diff}${unit} 후`];
};

const zfill = (num) => {
    return String(num).padStart(2, "0");
};

const mixin = {
    mounted: function () {},
    data() {
        return {
            logFlag: {
                pageView: { amplitude: true },
                doFamily: { amplitude: true },
                shopLink: { amplitude: true },
                channelLink: { amplitude: true },
                tag: { amplitude: true },
                replyLink: { amplitude: true },
                replyWrite: { amplitude: true },
                doLove: { amplitude: true },
                doShare: { amplitude: true },
            },
        };
    },
    computed: {
        loginFlag() {
            return this.$store.state.myInfo.sn != "";
        },
        adminFlag() {
            return this.$store.state.myInfo.adminFlag == "Y";
        },
        isApp() {
            return !this.$store.state.appInfo.ver == "";
        },
    },
    methods: {
        isAuthor(author) {
            return author && this.$store.state.myInfo.sn == author;
        },
        formatDate(date, type) {
            const dayAgo = (Date.now() - date) / (1000 * 60 * 60 * 24);
            if (dayAgo > 8) {
                date = new Date(date);
                let printDate =
                    date.getFullYear() +
                    "년 " +
                    zfill(date.getMonth() + 1) +
                    "월 " +
                    zfill(date.getDate()) +
                    "일 ";

                if (type === "type1") {
                    // XXXX년 XX월 XX일 오전/오후 00(시):00(분)
                    return (
                        printDate +
                        date.toLocaleString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                        })
                    );
                } else {
                    // XXXX년 XX월 XX일
                    return printDate;
                }
            } else {
                register("ko_KR", locale);
                return format(date, "ko_KR");
            }
        },
        comma(count) {
            if (count < 0) count = 0;
            return Number(count)
                ? count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0;
        },
        snackbar(serialid, message, type, height) {
            if (type == undefined) {
                type = "check";
            }
            if (height == undefined) {
                height = 56;
            }
            this.$store.commit("SET_SNACKBAR_FLAG", {
                serialid,
                message,
                type,
                height,
                value: true,
            });
            setTimeout(
                () =>
                    this.$store.commit("SET_SNACKBAR_FLAG", {
                        serialid,
                        undefined,
                        value: false,
                    }),
                3000
            );
        },
        logWrite(kind, message, params) {
            if (this.logFlag[kind].amplitude) {
                if (this.$store.state.myInfo.gSn) {
                    window.amplitude
                        .getInstance()
                        .setUserId(this.$store.state.myInfo.gSn);
                }
                window.amplitude.getInstance().logEvent(message, params);
            }
        },

        clickTrac(mseq, channelCode) {
            this.$store.commit("SET_MSEQ_REQUEST", {
                mseq: mseq,
                channelCode: channelCode,
            });
        },
    },
};

export default mixin;
