import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { catvid, appInfo } from "../util";

Vue.use(VueRouter);

const routes = [
    { path: "/", redirect: "/channel/416860" },
    { path: "/channel/", redirect: "/channel/416860" },
    {
        path: "/channel/:channel",
        name: "Channel",
        component: () => import("../views/Channel.vue"),
        beforeEnter: async (to, from, next) => {
            store.commit("SET_TOP_NAVI_TITLE", "GSSHOP 대표 프로그램 카페");
            store.commit("SET_TOP_EXPOS_YN", true);
            await store.commit("SET_CHANNEL_CODE", to.params.channel);
            next();
        },
    },
    {
        path: "/channel/:channel/:articleSn",
        name: "ChannelArticleShare",
        component: () => import("../views/Channel.vue"),
        beforeEnter: async (to, from, next) => {
            store.commit("SET_TOP_NAVI_TITLE", "GSSHOP 대표 프로그램 카페");
            store.commit("SET_TOP_EXPOS_YN", true);
            await store.commit("SET_CHANNEL_CODE", to.params.channel);
            next();
        },
    },
    {
        path: "/comment/:articleSn",
        name: "Comment",
        props: true,
        component: () => import("../views/Comment.vue"),
        beforeEnter: async (to, from, next) => {
            store.commit("SET_TOP_EXPOS_YN", false);
            if (Number.isNaN(Number(to.params.articleSn))) {
                alert("정상적인 경로로 접근해 주세요");
                next(from);
                return;
            }
            await Promise.all([
                store.dispatch("GET_ITEM", to.params.articleSn),
                store.dispatch("REPLY_ITEMS_SPECIAL", to.params.articleSn),
                store.dispatch("REPLY_ITEMS", to.params.articleSn),
            ]);
            next();
        },
    },
    {
        path: "/tag/:tag",
        name: "Tag",
        component: () => import("../views/Tag.vue"),
        beforeEnter: async (to, from, next) => {
            store.commit("SET_TOP_NAVI_TITLE", to.params.tag);
            store.commit("SET_TOP_EXPOS_YN", false);
            next();
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
        beforeEnter: async (to, from, next) => {
            if (store.state.myInfo.sn == "") {
                alert("로그인이 필요한 페이지 입니다.");
                window.location.href =
                    "https://m.gsshop.com/member/logIn.gs?returnurl=68747470733A2F2F70696e67706f6e672e677373686f702e636f6d";
                return;
            }
            store.commit("SET_TOP_NAVI_TITLE", "프로필 수정");
            store.commit("SET_TOP_EXPOS_YN", false);
            next();
        },
    },
    {
        path: "/article",
        name: "Article",
        component: () => import("../views/Article.vue"),
        beforeEnter: async (to, from, next) => {
            if (store.state.myInfo.adminFlag != "Y") {
                alert("작성 권한이 없습니다.");
                history.back();
                return;
            }
            store.commit("SET_TOP_NAVI_TITLE", "글 작성");
            store.commit("SET_TOP_EXPOS_YN", false);
            next();
        },
    },
    {
        path: "/article/:articleSk",
        name: "ArticleEdit",
        component: () => import("../views/ArticleEdit.vue"),
        beforeEnter: async (to, from, next) => {
            if (!store.state.article[to.params.articleSk]) {
                alert("정상적인 경로로 접근해주세요.");
                history.back();
                return;
            }
            if (store.state.myInfo.adminFlag != "Y") {
                alert("수정 권한이 없습니다.");
                history.back();
                return;
            }
            store.commit("SET_TOP_NAVI_TITLE", "글 수정");
            store.commit("SET_TOP_EXPOS_YN", false);
            next();
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior(to, from, savedPosition) {
    // 	if (savedPosition) {
    // 		return savedPosition;
    // 	} else {
    // 		return { x: 0, y: 0 };
    // 	}
    // },
});

router.beforeEach(async function (to, from, next) {
    let gSn = catvid(document.cookie);
    if (gSn && gSn != store.state.myInfo.gSn) {
        await store.dispatch("GET_MEMBER_INFO", {
            type: "gSn",
            sn: gSn,
        });
    }
    if (!store.state.appInfo.flag) {
        let info = await appInfo(navigator.userAgent);
        info.flag = true;
        store.commit("SET_APP_INFO", info);
    }
    await store.dispatch("GET_CHANNEL_INFO");
    if (document.getElementById("scrolling-techniques-4")) {
        document.getElementById("scrolling-techniques-4").scrollTop = 0;
    }
    next();
});

export default router;
