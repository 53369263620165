<template>
	<v-snackbar
		:timeout="3000"
		v-model="checkFlag"
		absolute
		bottom
		dark
		color="#fff"
		left
		text
		class="snackbar-noti1"
		:style="styleData"
	>
		<v-img
			max-height="24"
			max-width="24"
			class="mr-4"
			:src="getIconType()"
		></v-img>
		<div>{{ message }}</div>
	</v-snackbar>
</template>

<script>
export default {
	name: "SbType1",
	props: {
		serialid: String,
	},
	data() {
		return {
			checkFlag: false,
		};
	},
	computed: {
		toastFlag() {
			return this.$store.state.snackbar[this.serialid] || false;
		},
		styleData() {
			return {
				marginBottom: this.height + "px",
			};
		},
		message() {
			return this.$store.state.snackbar.message;
		},
		type() {
			return this.$store.state.snackbar.type;
		},
		height() {
			return this.$store.state.snackbar.height;
		},
	},
	watch: {
		toastFlag(value) {
			this.checkFlag = value;
		},
	},
	methods: {
		getIconType() {
			const checkIcon = require("../../icons/checkmark_fill.png");
			const infoIcon = require("../../icons/icon_comment_noti.png");
			if (this.type == "check") {
				return checkIcon;
			} else if (this.type == "info") {
				return infoIcon;
			} else {
				return checkIcon;
			}
		},
	},
};
</script>

<style>
</style>