import store from "@/store";
import {
    setValue,
    getData,
    postData,
    deleteData,
    patchData,
} from "../api/common";

const shareItem = async function (code, sk) {
    let author = 0;
    store.state.channel.items.some((d) => {
        if (d.code == code) {
            author = d.sn;
        }
    });
    let params = { author, sk };

    return getData("/articles/share", params);
};

const noticeItems = async function (code) {
    let author = 0;
    store.state.channel.items.some((d) => {
        if (d.code == code) {
            author = d.sn;
        }
    });

    let params = { author };

    return getData("/articles/notice", params);
};

const getItem = async function (sk) {
    const params = { sk };
    return getData("/post/item", params);
};

// items
const fetchItems = async function (code) {
    let author = 0;
    store.state.channel.items.some((d) => {
        if (d.code == code) {
            author = d.sn;
        }
    });

    let params = { author };
    if (store.state.articles[code].lastEvaluatedKey) {
        params["sk"] = store.state.articles[code].lastEvaluatedKey;
    }

    return getData("/articles/basic", params);
};

const tagItems = async function (tag) {
    let params = { tag };
    return getData("/articles/tag", params);
};

const getRecentWrite = async function (author) {
    return getData("/article/recentwrite", { author });
};

const getCount = async function (pk) {
    return getData("/article/count", { pk });
};
const getLoveArticle = async function (pk) {
    if (!store.state.myInfo.sn) {
        return false;
    }

    return getData("/post/item/love", { pk, sk: store.state.myInfo.sn });
};
const doLoveArticle = async function (pk) {
    if (!store.state.myInfo.sn) {
        return false;
    }
    var formData = new FormData();
    setValue(formData, "pk", pk);
    setValue(formData, "sk", store.state.myInfo.eSn);
    return postData("/post/item/love", formData);
};
const writeArticle = async function (params) {
    var formData = new FormData();
    setValue(formData, "author", params.author);
    setValue(formData, "sn", store.state.myInfo.eSn);
    setValue(formData, "reservationDate", params.reservationDate);
    setValue(formData, "message", params.message);
    setValue(formData, "youtube", params.youtube);
    params.files.forEach((file) => {
        formData.append("files", file);
    });
    setValue(formData, "linkUrl", params.linkUrl);

    return postData("/post/item", formData);
};
const editArticle = async function (params) {
    var formData = new FormData();
    setValue(formData, "pk", params.pk);
    setValue(formData, "sk", params.sk);
    // setValue(formData, "reservationDate", params.reservationDate);
    setValue(formData, "message", params.message);
    setValue(formData, "youtube", params.youtube);
    setValue(formData, "images", params.images);
    setValue(formData, "sn", store.state.myInfo.eSn);
    params.files.forEach((file) => {
        formData.append("files", file);
    });
    setValue(formData, "linkUrl", params.linkUrl);

    return patchData("/post/item", formData);
};

const setNotice = async function (author, sk) {
    let sn = store.state.myInfo.eSn;
    let params = { author, sk, sn };
    return getData("/post/item/notice", params);
};

const deleteArticle = async function (author, sk) {
    let sn = store.state.myInfo.eSn;
    let params = { author, sk, sn };
    return deleteData("/post/item", params);
};

const deleteArticleNotice = async function (author, sk) {
    let sn = store.state.myInfo.eSn;
    let params = { author, sk, sn };
    return deleteData("/post/item/notice", params);
};

export {
    shareItem,
    tagItems,
    //get
    getItem,
    fetchItems,
    noticeItems,
    getRecentWrite,
    getCount,
    getLoveArticle,
    //set
    doLoveArticle,
    writeArticle,
    editArticle,
    setNotice,
    deleteArticle,
    deleteArticleNotice,
};
