import store from "@/store";
import { setValue, getData, postData, deleteData } from "../api/common";

const replyItem = async function (pk, sk) {
    const params = { pk, sk };
    return getData("/reply/item", params);
};

const specialItems = async function (pk) {
    let response = await getData("/reply/items/special", { pk });
    return response;
};

const getReplyCount = async function (pk) {
    return getData("/reply/count", { pk });
};

const getReReplyCount = async function (pk) {
    return getData("/rereply/count", { pk });
};

const getLoveReply = async function (pk) {
    if (!store.state.myInfo.sn) {
        return false;
    }
    return getData("/love/reply/get", { pk, sk: store.state.myInfo.sn });
};

const getLoveReReply = async function (pk) {
    if (!store.state.myInfo.sn) {
        return false;
    }

    return getData("/love/rereply/get", { pk, sk: store.state.myInfo.sn });
};

const doLoveReply = async function (pk) {
    if (!store.state.myInfo.sn) {
        return false;
    }

    return getData("/love/reply/write", { pk, sk: store.state.myInfo.sn });
};

const doLoveReReply = async function (pk) {
    if (!store.state.myInfo.sn) {
        return false;
    }

    return getData("/love/rereply/write", { pk, sk: store.state.myInfo.sn });
};

const getMyRecent = async function (kind, pk) {
    return getData("/reply/myrecent", {
        kind,
        pk,
        author: store.state.myInfo.sn,
    });
};

const replyItems = async function (pk, lek) {
    let params = { pk };
    if (lek) {
        params["lek"] = lek;
    }
    return getData("/reply/items", params);
};

const reReplyItems = async function (pk, lek) {
    let params = { pk };
    if (lek) {
        params["lek"] = lek;
    }
    return getData("/rereply/items", params);
};

const writeReply = async function (params) {
    var formData = new FormData();
    let articleAuthor = 0;
    articleAuthor = store.state.article[params.pk]
        ? store.state.article[params.pk].author
        : store.state.postItem[params.pk].author;
    //articleAuthor = store.state.postItem[params.pk].author;
    setValue(formData, "articleAuthor", articleAuthor);
    setValue(formData, "pk", params.pk);
    setValue(formData, "author", store.state.myInfo.sn);
    setValue(formData, "message", params.message);
    setValue(formData, "sn", store.state.myInfo.eSn);
    params.files.forEach((file) => {
        formData.append("files", file);
    });

    return postData("/reply/item", formData);
};

const reReplyWrite = async function (params) {
    var formData = new FormData();
    setValue(formData, "pk", params.pk);
    setValue(formData, "message", params.message);
    params.files.forEach((file) => {
        formData.append("files", file);
    });
    setValue(formData, "sn", store.state.myInfo.eSn);

    return postData("/rereply/item", formData);
};

const setReplySpecial = async function (pk, sk, kind) {
    const params = {
        pk,
        sk,
        kind,
    };

    return getData("/reply/special/write", params);
};

const deleteReply = async function (pk, sk) {
    let articleAuthor = 0;
    articleAuthor = store.state.article[pk]
        ? store.state.article[pk].author
        : store.state.postItem[pk].author;
    let sn = store.state.myInfo.eSn;
    let params = { pk, sk, sn, articleAuthor };
    return deleteData("/reply/item", params);
};

const deleteReplySpecial = async function (pk, sk) {
    let params = { pk, sk };
    return getData("/reply/special/delete", params);
};

const deleteReReply = async function (pk, sk) {
    let sn = store.state.myInfo.eSn;
    let params = { pk, sk, sn };
    return deleteData("/rereply/item", params);
};

const getExcelReply = async function (pk) {
    let params = { pk };
    return getData("/reply/exceldownload", params);
};

// article/author
// article
// 두개의 API Gateway, lambda 삭제 필요
// 운영의 API Gateway, lambda 삭제 후 주석 삭제 예정

export {
    //get
    replyItem,
    specialItems,
    replyItems,
    reReplyItems,
    getReplyCount,
    getReReplyCount,
    getLoveReply,
    getLoveReReply,
    getMyRecent,
    getExcelReply,
    //set
    writeReply,
    reReplyWrite,
    doLoveReply,
    doLoveReReply,
    setReplySpecial,
    deleteReply,
    deleteReplySpecial,
    deleteReReply,
};
