import Vue from "vue";
import Vuex from "vuex";
import {
    getMemberInfo,
    getFamilyInfo,
    noticeItems,
    getItem,
    fetchItems,
    tagItems,
    getChannelInfo,
    replyItem,
    replyItems,
    specialItems,
    reReplyItems,
} from "../api";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        postItem: {},
        replyItemsSpecial: [],
        replyItems: [],
        replyItem: {},
        member: {},
        myInfo: {
            sn: "",
            adminFlag: "",
            gSn: "",
            eSn: "",
            nickname: "",
            thumb: "",
            family: {
                416860: "notyet",
                416861: "notyet",
                416862: "notyet",
                420782: "notyet",
            },
        },
        familyTrigger: false,
        title: "",
        appInfo: {
            ver: "",
            os: "",
            kind: "",
        },
        channel: {
            expos: true,
            selected: 0,
            items: [],
        },
        channelInfo: [],
        notices: {
            416860: { items: [] },
            416861: { items: [] },
            416862: { items: [] },
            420782: { items: [] },
        },
        articles: {
            416860: { lastEvaluatedKey: "", items: [] },
            416861: { lastEvaluatedKey: "", items: [] },
            416862: { lastEvaluatedKey: "", items: [] },
            420782: { lastEvaluatedKey: "", items: [] },
        },
        countCheck: false,
        article: {},
        tags: { lastEvaluatedKey: "", items: [] },
        replys: {},
        specialReplys: {},
        itemsOptions: {
            remainFlag: true,
        },
        topNaviTitle: "",
        topExposYn: true,
        snackbar: {},
        myReReplyItem: {},
        myReReplyItems: [],
        mseqRequest: { mseq: "", channel: "", flag: false },
    },
    mutations: {
        SET_APP_INFO(state, info) {
            state.appInfo = info;
        },
        SET_FAMILY_INFO(state, params) {
            state.myInfo.family[state.channel.selected] = params.fmlyYn;
            state.channel.items.some((d, i) => {
                if (state.channel.selected == d.code) {
                    state.channel.items[i].famCount = params.fmlyCnt;
                    return true;
                }
                return false;
            });
        },
        SET_CHANNEL_CODE(state, code) {
            state.channel.selected = code;
        },
        SET_CHANNEL_INFO(state, channelInfo) {
            state.channel.items = channelInfo;
        },
        SET_NOTICE_ITEMS(state, data) {
            state.notices[data.code] = {
                items: data.response.items,
            };
            data.response.items.forEach((d) => {
                Vue.set(state.article, d.sk, d);
            });
        },
        SET_ITEM(state, data) {
            Vue.set(state.postItem, data.sk, data);
        },
        SET_ITEMS(state, data) {
            state.articles[data.code] = {
                lastEvaluatedKey: data.response.LastEvaluatedKey || "",
                items: data.response.items,
            };
            data.response.items.forEach((d) => {
                Vue.set(state.article, d.sk, d);
            });
        },
        SET_MORE_ITEMS(state, data) {
            state.articles[data.code].lastEvaluatedKey =
                data.response.LastEvaluatedKey || "";
            state.articles[data.code].items = state.articles[
                data.code
            ].items.concat(data.response.items);
            data.response.items.forEach((d) => {
                Vue.set(state.article, d.sk, d);
            });
        },
        SET_TAG_ITEMS(state, data) {
            state.tags = data;
        },
        SET_ITEMS_OPTION(state, item) {
            state.itemsOptions[item.key] = item.option;
        },
        SET_ITEMS_OPTIONS(state, options) {
            state.itemsOptions = options;
        },
        SET_TOP_NAVI_TITLE(state, data) {
            state.topNaviTitle = data;
        },
        SET_TOP_EXPOS_YN(state, data) {
            state.topExposYn = data;
        },
        SET_SNACKBAR_FLAG(state, data) {
            if (data.serialid) {
                Vue.set(state.snackbar, data.serialid, data.value);
            }
            if (data.message) {
                Vue.set(state.snackbar, "message", data.message);
            }
            if (data.height) {
                Vue.set(state.snackbar, "height", data.height);
            }
            if (data.type) {
                Vue.set(state.snackbar, "type", data.type);
            }
        },

        SET_MEMBER_INFO(state, info) {
            Vue.set(state.member, info.sn, info);
        },
        SET_MY_INFO(state, info) {
            state.myInfo = info;
        },
        SET_REPLY_ITEMS_SPECIAL(state, data) {
            if (!state["replyItemsSpecial_" + data.pk.replace("reply#", "")]) {
                state["replyItemsSpecial_" + data.pk.replace("reply#", "")] =
                    [];
            }
            data.items.forEach((item) => {
                if (data.direction == "desc") {
                    state[
                        "replyItemsSpecial_" + data.pk.replace("reply#", "")
                    ].unshift(item.sk);
                } else {
                    state[
                        "replyItemsSpecial_" + data.pk.replace("reply#", "")
                    ].push(item.sk);
                }
                if (!state["replyItem_" + data.pk.replace("reply#", "")]) {
                    state["replyItem_" + data.pk.replace("reply#", "")] = {};
                }
                Vue.set(
                    state["replyItem_" + data.pk.replace("reply#", "")],
                    item.sk,
                    item
                );
            });
            state["replyItemsSpecialLek_" + data.pk.replace("reply#", "")] =
                data.lek;
        },
        SET_REPLY_ITEM(state, data) {
            Vue.set(
                state["replyItem_" + data.pk.replace("reply#", "")],
                data.sk,
                data
            );
        },
        SET_REPLY_ITEMS(state, data) {
            if (!state["replyItems_" + data.pk.replace("reply#", "")]) {
                state["replyItems_" + data.pk.replace("reply#", "")] = [];
            }
            const itemsSpecial =
                state["replyItemsSpecial_" + data.pk.replace("reply#", "")] ||
                [];
            const items = data.items.reduce(function (
                previousItem,
                currentItem
            ) {
                if (itemsSpecial.indexOf(currentItem.sk) < 0) {
                    previousItem.push(currentItem);
                }
                return previousItem;
            },
            []);
            items.forEach((item) => {
                if (data.direction == "desc") {
                    state[
                        "replyItems_" + data.pk.replace("reply#", "")
                    ].unshift(item.sk);
                } else {
                    state["replyItems_" + data.pk.replace("reply#", "")].push(
                        item.sk
                    );
                }
                if (!state["replyItem_" + data.pk.replace("reply#", "")]) {
                    state["replyItem_" + data.pk.replace("reply#", "")] = {};
                }
                Vue.set(
                    state["replyItem_" + data.pk.replace("reply#", "")],
                    item.sk,
                    item
                );
            });
            if (data.lek != "NO") {
                state["replyItemsLek_" + data.pk.replace("reply#", "")] =
                    data.lek;
            }
        },

        //기존에 이미 작성된 대댓글을 불러와서 스테이트에 설정해주기
        SET_RE_REPLY_ITEMS(state, data) {
            if (!state["reReplyItems_" + data.pk.replace("reReply#", "")]) {
                state["reReplyItems_" + data.pk.replace("reReply#", "")] = [];
            }
            data.items.forEach((item) => {
                if (data.direction == "desc") {
                    state[
                        "reReplyItems_" + data.pk.replace("reReply#", "")
                    ].unshift(item.sk);
                } else {
                    state[
                        "reReplyItems_" + data.pk.replace("reReply#", "")
                    ].push(item.sk);
                }
                if (!state["reReplyItem_" + data.pk.replace("reReply#", "")]) {
                    state["reReplyItem_" + data.pk.replace("reReply#", "")] =
                        {};
                }
                Vue.set(
                    state["reReplyItem_" + data.pk.replace("reReply#", "")],
                    item.sk,
                    item
                );
            });
            if (data.lek != "NO") {
                state["reReplyItemsLek_" + data.pk.replace("reReply#", "")] =
                    data.lek;
            }
        },

        //사용자가 막 작성한 대댓글에 대한 스테이트 관리
        SET_RE_REPLY_NEW_ITEMS(state, data) {
            var pk = data.pk.replace("reReply#", "");
            if (!state["myReReplyItems_" + data.pk.replace("reReply#", "")]) {
                state["myReReplyItems_" + data.pk.replace("reReply#", "")] = [];
            }
            if (!state["myReReplyItem_" + data.pk.replace("reReply#", "")]) {
                state["myReReplyItem_" + data.pk.replace("reReply#", "")] = {};
            }

            //Items -> 이 댓글에 어떤 대댓글 번호들이 있나
            //Item -> 이 댓글에 어떤 대댓글이 달렸나.
            // data -> pk 댓글번호
            // item -> sk 대댓글번호 message 메세지 author 작성자 regDate 작성 시간 pk 리플라이#댓글번호

            var len =
                state["myReReplyItems_" + data.pk.replace("reReply#", "")]
                    .length;

            var originList = state["myReReplyItem"][pk]
                ? state["myReReplyItem"][pk]
                : [];
            originList = originList.concat(data.items[0]);

            Vue.set(state["myReReplyItem"], pk, originList);

            Vue.set(
                state["myReReplyItems_" + data.pk.replace("reReply#", "")],
                len,
                data.items[0].sk
            );

            Vue.set(
                state["myReReplyItem_" + data.pk.replace("reReply#", "")],
                data.items[0].sk,
                data.items[0]
            );
        },
        SET_MSEQ_REQUEST(state, data) {
            var flag = !state["mseqRequest"].flag;

            Vue.set(state["mseqRequest"], "mseq", data.mseq);
            Vue.set(state["mseqRequest"], "channel", data.channelCode);
            Vue.set(state["mseqRequest"], "flag", flag);
        },
        SET_SELECTED_CHANNEL(state, data) {
            Vue.set(state["channel"], "selected", data);
        },
        SET_FAMILY_TRIGGER(state) {
            state.familyTrigger = !state.familyTrigger;
        },
    },
    actions: {
        async GET_MEMBER_INFO({ commit }, params) {
            if (params.type == "gSn" && this.state.myInfo.gSn == params.sn) {
                // 본인 정보를 구하는 경우이고
                // 이미 본인의 정보가 있을 경우
                // 새로 요청하는 정보가 기존 고객번호와 동일하면 스킵
                return;
            }
            if (
                // 이미 고객정보가 있는경우이거나
                // 현재 로딩중일경우 스킵
                this.state.member[params.sn] ||
                this.state.member["loadingFlag_" + params.sn]
            ) {
                return;
            }
            this.state.member["loadingFlag_" + params.sn] = true; // 로딩중으로 상태 변경
            let response = await getMemberInfo(params.type, params.sn);
            // DB에 회원정보가 없으면
            if (response.code && response.code == "ERROR") {
                response = {
                    nickname: "noname",
                    sn: params.sn,
                    thumb: "https://gspingpong.s3.ap-northeast-2.amazonaws.com/images/unknown/001.png",
                };
            }
            // 회원 정보가 설정 되었으면
            if (response.sn) {
                commit("SET_MEMBER_INFO", response);
                if (params.type == "gSn") {
                    response["gSn"] = params.sn;
                    response["family"] = {
                        416860: "notyet",
                        416861: "notyet",
                        416862: "notyet",
                        420782: "notyet",
                    };
                    commit("SET_MY_INFO", response);
                }
                return;
            }
            this.state.member["loadingFlag_" + params.sn] = false; // 정상적인 결과가 아닐경우 재시도 가능하도록 상태 변경
            return;
        },
        async GET_FAMILY_INFO({ commit }) {
            if (
                this.state.myInfo.family[this.state.channel.selected] ==
                    "notyet" &&
                this.state.myInfo.gSn != ""
            ) {
                const response = await getFamilyInfo();
                commit("SET_FAMILY_INFO", {
                    fmlyYn: response.fmlyYn,
                    fmlyCnt: response.fmlyCnt,
                });
            } else if (
                this.state.myInfo.gSn == "" &&
                this.state.myInfo.sn == "" &&
                this.state.channel.items.find(
                    (e) => e.code == this.state.channel.selected
                ).famCount == 0
            ) {
                const response = await getFamilyInfo();
                commit("SET_FAMILY_INFO", {
                    fmlyYn: "notyet",
                    fmlyCnt: response.fmlyCnt,
                });
            }
            return this.state.myInfo.family[this.state.channel.selected];
        },
        async GET_CHANNEL_INFO({ commit }) {
            if (this.state.channel.items.length) {
                return;
            }
            const response = await getChannelInfo();
            commit("SET_CHANNEL_INFO", response);
            return response;
        },
        async NOTICE_ITEMS({ commit }) {
            const channelCode = this.state.channel.selected;
            if (this.state.notices[channelCode].items.length) {
                return [];
            }
            const response = await noticeItems(channelCode);
            commit("SET_NOTICE_ITEMS", {
                code: channelCode,
                response,
            });
            return response.items;
        },
        async GET_ITEM({ commit }, sk) {
            if (
                this.state.postItem[sk] ||
                this.state.postItem["_loadingFlag_" + sk]
            ) {
                // 이미 글정보가 있을경우 이거나
                // 현재 로딩중일경우 스킵
                return;
            }
            this.state.postItem["_loadingFlag_" + sk] = true; // 로딩중으로 상태 변경
            let response = await getItem(sk);
            // DB에 글정보가 없거나 에러일 경우
            if (response.code && response.code == "ERROR") {
                console.log("API ERROR : " + response.message);
                response = {
                    sk,
                };
            }
            // 정상적으로 결과가 있으면
            if (response.sk) {
                commit("SET_ITEM", response);
                return;
            }
            this.state.postItem["_loadingFlag_" + sk] = false; // 정상적인 결과가 아닐경우 재시도 가능하도록 상태 변경
            return;
        },
        async FETCH_ITEMS({ commit }) {
            const channelCode = this.state.channel.selected;
            if (this.state.articles[channelCode].items.length) {
                return [];
            }
            const response = await fetchItems(channelCode);
            response.items = response.items.filter(
                (i) =>
                    !this.state.notices[channelCode].items.some(
                        (n) => i.sk === n.sk
                    )
            );
            commit("SET_ITEMS", {
                code: channelCode,
                response,
            });
            return response.items;
        },
        async FETCH_MORE_ITEMS({ commit }) {
            // if (this.state.myInfo.family[this.state.channel.selected] != "Y") {
            //     return [];
            // }
            const response = await fetchItems(this.state.channel.selected);
            if (response.items != undefined) {
                response.items = response.items.filter(
                    (i) =>
                        !this.state.notices[
                            this.state.channel.selected
                        ].items.some((n) => i.sk === n.sk)
                );
                commit("SET_MORE_ITEMS", {
                    code: this.state.channel.selected,
                    response,
                });
                return response.items;
            } else {
                return response;
            }
        },
        async TAG_ITEMS({ commit }, tag) {
            if (this.state.tags.items.length) {
                return [];
            }
            const response = await tagItems(tag);
            commit("SET_TAG_ITEMS", response);
            return response.items;
        },
        async REPLY_ITEM({ commit }, params) {
            if (!this.state["replyItem_" + params.pk]) {
                Vue.set(this.state, "replyItem_" + params.pk, {});
            }
            if (
                this.state["replyItem_" + params.pk][params.sk] ||
                this.state["replyItem_" + params.pk][
                    "_loadingFlag_" + params.sk
                ]
            ) {
                // 이미 댓글정보가 있을경우 이거나
                // 현재 로딩중일경우 스킵
                return;
            }
            this.state["replyItem_" + params.pk][
                "_loadingFlag_" + params.sk
            ] = true; // 로딩중으로 상태 변경
            let response = await replyItem(params.pk, params.sk);
            // DB에 댓글정보가 없거나 에러일 경우
            if (response.code && response.code == "ERROR") {
                console.log("API ERROR : " + response.message);
                response = {
                    pk: params.pk,
                    sk: params.sk,
                };
            }
            // 정상적으로 결과가 있으면
            if (response.sk) {
                commit("SET_REPLY_ITEM", response);
                return;
            }
            this.state["replyItem_" + params.pk][
                "_loadingFlag_" + params.sk
            ] = false; // 정상적인 결과가 아닐경우 재시도 가능하도록 상태 변경
            return;
        },
        async REPLY_ITEMS({ commit }, pk) {
            const lek = this.state["replyItemsLek_" + pk];
            if (lek == "") {
                console.log("더이상 가져올 댓글 목록이 없습니다.");
                return;
            }
            const response = await replyItems(pk, lek);
            if (response.code == "ERROR") {
                console.log("API ERROR : " + response.message);
                return;
            }
            if (response.Count) {
                commit("SET_REPLY_ITEMS", {
                    pk,
                    items: response.Items,
                    lek: response.LastEvaluatedKey
                        ? response.LastEvaluatedKey
                        : "",
                });
            } else {
                this.state["replyItemsLek_" + pk] = "";
                this.state["replyItems_" + pk] = [];
                this.state["replyItem_" + pk] = {};
            }
            return;
        },
        async REPLY_ITEMS_SPECIAL({ commit }, pk) {
            const lek = this.state["replyItemsSpecialLek_" + pk];
            if (lek == "") {
                console.log("더이상 가져올 댓글 목록이 없습니다.");
                return;
            }
            const response = await specialItems(pk);
            if (response.length) {
                // 중복호출 방지를 위해 LastEvaluatedKey는 셋팅을 하지만
                // 실질적으로 SPECIAL리스트의 특성상 수가 많지 않아 한번에 셋팅되는것으로 가정하고
                // 더보기 로직은 구현하지 않음
                commit("SET_REPLY_ITEMS_SPECIAL", {
                    pk,
                    items: response,
                    lek: "",
                });
            } else {
                this.state["replyItemsSpecialLek_" + pk] = "";
            }
            return;
        },
        async RE_REPLY_ITEMS({ commit }, pk) {
            const lek = this.state["reReplyItemsLek_" + pk];
            if (lek == "") {
                console.log("더이상 가져올 댓글 목록이 없습니다.");
                return;
            }
            const response = await reReplyItems(pk, lek);
            if (response.code == "ERROR") {
                console.log("API ERROR : " + response.message);
                return;
            }
            if (response.Count) {
                commit("SET_RE_REPLY_ITEMS", {
                    pk,
                    items: response.Items,
                    lek: response.LastEvaluatedKey
                        ? response.LastEvaluatedKey
                        : "",
                });
            } else {
                this.state["reReplyItemsLek_" + pk] = "";
            }
            return;
        },
    },
    modules: {},
});
