<template>
	<v-app-bar
		absolute
		height="48"
		color="white"
		flat
		extension-height="123"
		scroll-target="#scrolling-techniques-4"
		class="app-bar"
	>
		<v-btn icon small color="black" @click="historyBack">
			<!-- <v-icon>mdi-arrow-left</v-icon> -->
			<!-- <template>
        <arrow-back></arrow-back>
      </template> -->
			<IconArrowBack />
		</v-btn>
		<!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

		<v-spacer />
		<v-toolbar-title>
			<!-- <span class="top-title">GSSHOP 대표 프로그램 카페</span> -->
			<h5>{{ topNaviTitle }}</h5>
		</v-toolbar-title>
		<v-spacer />

		<!-- <v-btn icon>
            <v-icon>mdi-heart</v-icon>
        </v-btn> -->

		<!-- <v-avatar
            color="grey lighten-2"
            size="33"
            style="margin-top:5px;"
        >
            <v-icon dark>
            mdi-account-circle
            </v-icon>
        </v-avatar>

        <v-btn icon color="grey lighten-2">
            <v-icon>mdi-dots-vertical</v-icon>
        </v-btn> -->
		<template v-slot:extension v-if="topExposYn">
			<v-tabs
				align-with-title
				grow
				show-arrows
				background-color="white"
				class="profile-tabs"
				active-class="active-box"
			>
				<v-tabs-slider color="white"></v-tabs-slider>
				<v-tab
					v-for="channel in channels"
					:key="channel.code"
					:to="'/channel/' + channel.code"
					@click="
						logWrite('channelLink', 'Click_커뮤니티_채널선택', {
							channel: channel.code,
						})
					"
				>
					<v-sheet>
						<v-img
							class="profile-img d-inline-block"
							width="70"
							cover
							:src="channel.image.thumb"
						>
						</v-img>
						<div class="body-3">{{ channel.title }}</div>
					</v-sheet>
				</v-tab>
			</v-tabs>

			<!-- header tag 고정 -->

			<v-card-text
				class="pa-0 ma-0 tag-wrap"
				v-scroll:#scrolling-techniques-4="handleScroll"
			>
				<v-chip-group class="tag-box" v-if="channelInfo && isVisible">
					<v-chip
						v-for="(tag, index) in tags"
						:key="index"
						:to="'/tag/' + tag"
						@click="
							logWrite('tag', 'Click_커뮤니티_해시태그_스크롤', {
								channel: $route.params.channel,
							})
						"
						small
						class="ma-2"
						color="#F9F1FF"
						text-color="#7E3DD9"
					>
						<span class="tag-txt">#{{ tag }}</span>
					</v-chip>
				</v-chip-group>
			</v-card-text>
		</template>
	</v-app-bar>
</template>

<script>
import IconArrowBack from "../icons/svg/arrowBack.svg";
export default {
	components: {
		IconArrowBack,
	},
	data: () => ({
		scrollPostion: 0,
		isVisible: false,
	}),
	computed: {
		channels() {
			return this.$store.state.channel.items;
		},
		topExposYn() {
			return this.$store.state.topExposYn;
		},
		topNaviTitle() {
			return this.$store.state.topNaviTitle;
		},
		tags() {
			if (this.channelInfo) {
				return this.channelInfo.tags;
			}
			return [];
		},
		channelInfo() {
			return this.$store.state.channel.items.find(
				(e) => e.code == this.$route.params.channel
			);
		},
	},
	methods: {
		historyBack() {
			//let hist = window.history;
			// let docu = document.referrer;
			// let before = location.pathname;
			// let statLoca = this.$router.history._startLocation;
			// console.log(hist);
			// console.log(docu + "!!!");
			// console.log(before);
			// console.log(this.$router.history._startLocation);
			// if (before == statLoca) {
			// 	//console.log(this.$router.go(-1));
			// }
			// let statLoca = this.$router.history._startLocation;
			// let before = location.pathname;
			// //this.$router.go(-1);
			// let after = location.pathname;
			// console.log("/channel/" + this.$store.state.channel.selected);
			// console.log(statLoca, before, after);
			// console.log(location);
			// console.log(hist);
			// console.log(this.$router);
			// console.log(this.$route);
			// console.log(window.navigator);
			//window.navigator.app.backHistory();
			// alert(window.navigation);
			// console.log(window.history.length);
			// console.log(document.referrer);
			// if (window.navigation.canGoBack) {
			// 	window.history.back();
			// 	//return false;
			// } else {
			// 	if (this.isApp) {
			// 		location.href = "toapp://close";
			// 		return false;
			// 	} else {
			// 		this.$router.go(-1);
			// 		return false;
			// 	}
			// }
			//this.$router.go(-1);

			if (this.isApp) {
				location.href = "toapp://back";
			} else {
				this.$router.go(-1);
			}
		},
		handleScroll(e) {
			var setHeight = document.querySelector(".channel-img").clientHeight;
			if (e.target.scrollTop > setHeight + 72) {
				this.isVisible = true;
				document
					.querySelector(".app-bar")
					.classList.add("app-bar-sticky");
				document
					.querySelector(".profile-tabs")
					.classList.add("profile-small");
			} else {
				this.isVisible = false;
				document
					.querySelector(".app-bar")
					.classList.remove("app-bar-sticky");
				document
					.querySelector(".profile-tabs")
					.classList.remove("profile-small");
			}
		},
	},
};
</script>

<style scoped lang="scss">
.app-bar::v-deep {
	border-bottom: 1px solid rgba(83, 93, 145, 0.18) !important;
	z-index: 10 !important;
	.v-toolbar__content {
		border-bottom: 1px solid #eee;
	}
	.v-slide-group__wrapper {
		border-bottom: 1px solid #eaecf5;
	}
	.v-toolbar__extension {
		flex-direction: column;
		padding: 4px 0;
		.v-tabs:first-child {
			margin-left: 0;
		}
	}
	&.app-bar-sticky {
		box-sizing: border-box;
		height: 112px !important;
		.v-toolbar__extension {
			height: 56px !important;
		}
	}
	.v-slide-group__next {
		display: none;
	}
}

.profile-tabs::v-deep {
	flex-direction: column;
	.v-tab {
		padding: 0;
		&:before {
			background-color: transparent;
		}
	}
	div.v-toolbar__extension {
		height: 123px !important;
	}
	.profile-img {
		position: relative;
		border-radius: 50%;
		overflow: hidden;
	}
	&.profile-small {
		.v-slide-group__content {
			overflow-x: auto;
			flex: auto;
			-ms-overflow-style: none;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		.theme--light.v-sheet {
			display: flex;
			background: #f5f6fa;
			border-radius: 16px;
			height: 32px;
			padding: 0 12px 0 8px;
			align-items: center;
		}
		.profile-img {
			width: 20px !important;
			height: 20px;
			margin-right: 6px;
		}
		.v-tab {
			margin-left: 12px;
		}
		.body-3 {
			font-size: 14px;
		}
		.active-box {
			.theme--light.v-sheet {
				background: #fff;
				border: 1px solid #191923;
			}
			.body-3 {
				color: #191923;
			}
		}
	}
}

.active-box::v-deep {
	.profile-img:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 2px solid rgba(109, 115, 204, 0.9);
		border-radius: 50%;
	}
	.body-3 {
		font-weight: 600;
		color: #5829bb;
	}
}
div.v-toolbar__extension::v-deep {
	background-color: white;
}
.v-tabs::v-deep {
	z-index: 10;
	.v-slide-group__prev {
		display: none !important;
	}
}

// tag style
.tag-wrap::v-deep {
	width: 100%;
	background: #fff;
	border-bottom: 1px solid #eaecf5;
	// &.tag-show {
	//     display: block;
	// }
	.tag-box {
		width: 100%;
		overflow: hidden;

		.v-chip.v-size--small {
			height: 32px;
			border-radius: 16px;
			&:first-child {
				margin-left: 16px !important;
			}
		}
		.tag-txt {
			font-weight: 600;
			font-size: 13px;
		}
		.v-slide-group__wrapper {
			flex-wrap: nowrap;
			overflow-x: auto;
			overflow-y: hidden;
			padding-bottom: 16px;
			margin-bottom: -20px;
		}
	}
}
</style>
