const catvid = function (ecid) {
    if (!ecid) {
        return "";
    }
    let catvid = "";
    const tempArray = ecid.match(/catvid=\d*~/gi);
    if (tempArray && tempArray.length > 0) {
        catvid = tempArray[0].replace("catvid=", "").replace("~", "");
    }
    return catvid;
};

const appInfo = async function (userAgent) {
    let headerInfo = userAgent
        .replace(/ /gi, "")
        .match(/([0-9]{3}),([a-zA-Z]{2}),([A-Z]{2}[0-9]{3})$/gi);
    let info = { ver: "", os: "", kind: "" };
    if (headerInfo) {
        let tempInfo = headerInfo[0].split(",");
        info.ver = tempInfo[0];
        info.os = tempInfo[1]; // BT => 안드로이드, BS => IOS
        info.kind = tempInfo[2];
    }
    return info;
};

export { catvid, appInfo };
