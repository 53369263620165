var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showDownloadBtn && !_vm.bottomFlag && !_vm.isApp)?_c('v-btn',{class:{
			'navi-btn': true,
			'download-comment': true,
			'low-btn': !_vm.topBtn,
		},style:(_vm.excelBtnPostion),on:{"click":_vm.downloadExcel}},[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-microsoft-excel")])],1):_vm._e(),(_vm.showWriteBtn && !_vm.bottomFlag)?_c('v-btn',{class:{
			'navi-btn': true,
			'write-article': true,
			'low-btn': !_vm.topBtn,
		},style:(_vm.writeBtnPostion),attrs:{"to":"/article"}},[_c('v-icon',[_vm._v("mdi-file-plus-outline")])],1):_vm._e(),(_vm.topBtn)?_c('v-btn',{class:{
			'navi-btn': true,
			'page-top': true,
		},style:(_vm.topBtnPostion),on:{"click":_vm.scrollTop}}):_vm._e(),(!_vm.isApp)?_c('v-bottom-navigation',{staticClass:"navigation-box",attrs:{"value":_vm.value,"color":"#191923","grow":"","app":""}},[_c('v-btn',{attrs:{"href":"https://m.gsshop.com/index.gs?mseq=398042"}},[_c('span',{staticClass:"nav-txt"},[_vm._v("홈")]),_c('v-img',{attrs:{"max-height":"28","max-width":"28","src":require("../icons/nav_bottom_home.png")}})],1),_c('v-btn',{attrs:{"href":"https://m.gsshop.com/m/sect/category.gs?mseq=398043"}},[_c('span',{staticClass:"nav-txt"},[_vm._v("카테고리")]),_c('v-img',{attrs:{"max-height":"28","max-width":"28","src":require("../icons/nav_bottom_menu.png")}})],1),_c('v-btn',{attrs:{"href":"https://m.gsshop.com/mygsshop/myshopInfo.gs?mseq=398041"}},[_c('span',{staticClass:"nav-txt"},[_vm._v("마이쇼핑")]),_c('v-img',{attrs:{"max-height":"28","max-width":"28","src":require("../icons/nav_bottom_user.png")}})],1),_c('v-btn',{attrs:{"href":"https://m.gsshop.com/section/myWishMain.gs?mseq=410808"}},[_c('span',{staticClass:"nav-txt"},[_vm._v("찜")]),_c('v-img',{attrs:{"max-height":"28","max-width":"28","src":require("../icons/nav_bottom_zzim.png")}})],1),_c('v-btn',{attrs:{"href":"http://m.gsshop.com/section/recntView?mseq=410809"}},[_c('span',{staticClass:"nav-txt"},[_vm._v("최근본상품")]),_c('v-img',{staticClass:"recent-img-box",attrs:{"max-height":"28","max-width":"28","src":_vm.rcntPrdImgPath}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }